<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="p-10">
    <div
      class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6 rounded-t-md shadow-md"
    >
      <div
        class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap"
      >
        <div class="ml-4 mt-2">
          <h3 class="text-lg leading-6 font-medium body__header--text">
            {{ categoryName }} sub-categories
          </h3>
        </div>
        <div class="ml-4 mt-2 flex-shrink-0">
          <button
            @click="openForm"
            type="button"
            class="relative inline-flex items-center px-1 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white body__button"
          >
            <PlusSmIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div v-if="showForm">
        <EditTicketSubCategorie
          :categoryId="categoryId"
          @closeForm="this.showForm = false"
          @activeBanner="
            (activeBanner = true), getSubCategories(), (key = !key)
          "
          @successMsg="successMsg = $event"
          @success="isSuccess = $event"
        />
      </div>
    </div>

    <TablePagination
      :categoryId="categoryId"
      rows="20"
      :tableData="subCategories"
      @activeBanner="(activeBanner = true), getSubCategories(), (key = !key)"
      @successMsg="successMsg = $event"
      @success="isSuccess = $event"
    />
  </div>
</template>

<script>
import axios from "axios";

import { PlusSmIcon } from "@heroicons/vue/solid";

import TablePagination from "../../../components/TablePagination.vue";
import SuccessBanner from "../../../components/SuccessBanner.vue";
import EditTicketSubCategorie from "../../../components/configuration/support/EditTicketSubCategorie.vue";

export default {
  props: ["categoryId", "categoryName"],
  components: {
    PlusSmIcon,
    TablePagination,
    SuccessBanner,
    EditTicketSubCategorie,
  },
  data() {
    return {
      subCategories: [],
      activeBanner: false,
      successMsg: "",
      isSuccess: false,
      isLoading: true,
      fullPage: false,
      showForm: false,
      key: false,
    };
  },
  methods: {
    async getSubCategories() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/ticketCategories/${
            this.categoryId
          }`
        );
        this.subCategories = res.data.ticket_sub_categories;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    openForm() {
      this.showForm = !this.showForm;
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    this.getSubCategories();
  },
};
</script>

<style></style>
